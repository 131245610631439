<template>
	<div class="grid mb-4">
		<div class="title">Организация</div>
		<form class="organization" @submit.prevent>
			<BaseGroup title="Основные данные">
				<BaseInput v-model="organization.name" placeholder="Название" />
				<BaseInput v-model="organization.inn" placeholder="ИНН" />
			</BaseGroup>
			<BaseButton @click="editOrganization"> Редактировать </BaseButton>
			<BaseButton view="danger" @click="deleteOrganization(organization.id)"> Удалить </BaseButton>
		</form>
	</div>
</template>

<script>
import BaseInput from '@/components/ui/BaseInput'
import BaseGroup from '@/components/ui/BaseGroup'
import BaseButton from '@/components/ui/BaseButton'
import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useApi } from '@/modules/api'

export default {
	name: 'OrganizationDetail',
	components: {
		BaseInput,
		BaseGroup,
		BaseButton
	},
	setup () {
		const route = useRoute()
		const router = useRouter()
		const organization = ref({})

		const editOrganization = async () => {
			const { post } = useApi('/organization/')
			await post({
				id: organization.value.id,
				inn: organization.value.inn,
				name: organization.value.name
			})
			router.push({ name: 'Organizations' })
		}

		const deleteOrganization = async (id) => {
			const { del } = useApi(`/organization/${id}`)
			await del()
			router.push({ name: 'Organizations' })
		}

		onMounted(async () => {
			const { get, data } = useApi(`/organization/${route.params.id}`)
			await get()
			organization.value = data.value
		})

		return {
			organization,
			editOrganization,
			deleteOrganization
		}
	}
}
</script>

<style lang="scss">
	.organization {
		// display: flex;
		.group {
			width: 50%;
			margin-bottom: 20px;
		}
		.button {
			&:not(:last-of-type) {
				margin-right: 10px;
			}
		}
	}
</style>
